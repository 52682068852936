import React, { Component } from "react";
import { Box, Button, colors, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import moment from "moment";

const muiStyles = (theme: Theme) => ({
  container: {
    background: "#FFFFFFCC",
    padding: "20px",
    borderRadius: "14px",
    cursor: "pointer",
    boxShadow: "0px 12px 26px -20px #15151575",

    // width: '20%',

    "& img": {
      width: "3rem",
      height: "3rem",
      borderRadius: ".4rem",
    },

    "& .empty_img": {
      width: "3rem",
      height: "3rem",
    },

    "&:last-child": {
      marginBottom: "3px",
    },

    "& h5": {
      fontSize: "20px",
      color: "#093554",
      fontWeight: 600,
      fontFamily: "Montserrat",
    },

    "& button": {
      padding: ".5rem 2rem",
      margin: "1rem 0",
      borderRadius: ".8rem",

      "& span": {
        color: "#fff",
        fontWeight: "bold",
        fontSize: "0.65rem",
      },
    },

    "& .title": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Montserrat",
      width: "2rem",
      color: "#093554",
    },

    "& .more": {
      fontSize: "14px",
      color: "#015C93",
      fontWeight: 500,
      fontFamily: "Montserrat",
    },

    "&.selectedCandidateSt": {
      border: "2px solid #015C9347",
    },
    "& .watched-datab-data": {
      background: "#015C93",
      color: "#FAFAF8",
      padding: "4px 10px",
      borderRadius: "6px",
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});

interface CDprops {
  id: number;
  name: string;
  higest_education: string;
  image: string;
  summary: string;
  prev: {
    data: string;
    more: number;
  };
  skills: {
    data: string;
    more: number;
  };
}

interface Props {
  classes: any;
  // candidateData: CDprops[];
  candidateData: any;
  isLoggedIn?: boolean;
  marginBottom?: number;
  showEmptyImg?: boolean;
  isSelectedCan?: boolean;
  sendSelectedCandidateData?: any;
  filterScreen?: boolean;
}
export interface CardState {
  skillsShowAll: boolean;
  prevShowAll: boolean;
  selectedCanID: any;
}

class CandidateCards extends Component<Props, CardState> {
  state: CardState = {
    skillsShowAll: false,
    prevShowAll: false,
    selectedCanID: null,
  };

  handleCardClick = () => {
    const { sendSelectedCandidateData, candidateData } = this.props;
    if (sendSelectedCandidateData) {
      sendSelectedCandidateData(candidateData);
    }
    this.setState({ selectedCanID: candidateData?.temporary_user_profile?.id });
  };

  render() {
    const {
      classes,
      candidateData,
      isLoggedIn,
      marginBottom,
      showEmptyImg,
      isSelectedCan,
    } = this.props;

    let currentPositionData;
    let previousPositions: any = [];

    let experienceData: any =
      candidateData?.temporary_user_profile?.work_experience ||
      candidateData?.position ||
      [];

    let candidateSkills: any =
      candidateData?.temporary_user_profile?.skills ||
      candidateData?.skills ||
      null;

    experienceData?.map((item: any) => {
      if (item?.current) {
        currentPositionData = `${item?.position}${
          item.company ? " at " + item.company : ""
        }${item.location ? ", " + item.location + " " : ""}${
          candidateData?.experience
            ? ", " + candidateData?.experience + " years of experience"
            : ""
        }`;
      } else {
        if (item?.position) {
          previousPositions.push(
            `${item?.position}${item.company ? " at " + item.company : ""}`
          );
        }
      }
    });

    return (
      <>
        {this.props.filterScreen ? (
          <div> {this.props.candidateData}</div>
        ) : (
          <Box
            className={`${classes.container} ${
              isSelectedCan ? "selectedCandidateSt" : ""
            }`}
            mb={marginBottom}
            onClick={this.handleCardClick}
          >
            {isLoggedIn ? (
              <img src={candidateData?.photo_url?.url} alt="user_avatar" />
            ) : (
              showEmptyImg && <Box className="empty_img" />
            )}
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Typography variant="h5">
                {candidateData?.full_name || "-"}
              </Typography>
              {candidateData?.watched_records && (
                <Typography component="span" className="watched-datab-data">
                  Watched
                </Typography>
              )}
            </Box>
            <p className="candidate-desc">{currentPositionData}</p>
            {isLoggedIn && (
              <Button
                style={{ visibility: isLoggedIn ? "visible" : "hidden" }}
                variant="contained"
              >
                Reveal Contacts
              </Button>
            )}
            <Box mb={2} mt={2}>
              {previousPositions?.length !== 0 && (
                <Box display="flex">
                  <Box pr={2}>
                    <Typography className="title">Prev</Typography>
                  </Box>
                  <Box>
                    <p className="candidate-skills-text">
                      {/* {previousPositions.length > 0
                        ? [...previousPositions]
                            .splice(
                              0,
                              this.state.prevShowAll
                                ? previousPositions?.length
                                : 4
                            )
                            ?.join(", ")
                        : "-"} */}
                      {previousPositions?.length > 0 && previousPositions[0]}
                    </p>
                    {/* {previousPositions.length > 0 &&
                      previousPositions.length > 4 && (
                        <Typography
                          variant="h6"
                          className="more"
                          onClick={() =>
                            this.setState((prev) => ({
                              prevShowAll: !prev.prevShowAll,
                            }))
                          }
                        >
                          {this.state.prevShowAll
                            ? "Show less"
                            : `${previousPositions.length - 4} more`}
                        </Typography>
                      )} */}
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              {candidateSkills && (
                <Box display="flex">
                  <Box pr={2}>
                    <Typography className="title">Skills</Typography>
                  </Box>
                  <Box>
                    <p className="candidate-skills-text">
                      {candidateSkills
                        ? [...candidateSkills]
                            .splice(
                              0,
                              this.state.skillsShowAll
                                ? candidateSkills?.length
                                : 8
                            )
                            ?.join(", ")
                        : "-"}
                    </p>
                    {candidateSkills?.length > 0 &&
                      candidateSkills?.length > 8 && (
                        <Typography
                          variant="h6"
                          className="more"
                          onClick={() =>
                            this.setState((prev) => ({
                              skillsShowAll: !prev.skillsShowAll,
                            }))
                          }
                        >
                          {this.state.skillsShowAll
                            ? "Show less"
                            : `${candidateSkills?.length - 8} more`}
                        </Typography>
                      )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </>
    );
  }
}

export default withStyles(muiStyles)(CandidateCards);
